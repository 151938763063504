.dropdown {
  position: relative;
  z-index: 99;
}

.dropdown__toggle {
  border: 0;
  outline: 0;
  background-color: transparent;
  position: relative;
  cursor: pointer;
}

.dropdown__toggle > i {
  font-size: 2.5rem;
  color: #455560;
}

.dropdown__toggle-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -7px;
  right: -4px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #349eff;
  color: #fff;
  font-size: 0.8rem;
}

.dropdown__content {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: max-content;
  width: 353px;
  height: 500px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
  overflow: hidden;
  transform-origin: top right;
  transform: scale(0);
  transition: transform 0.3s ease 0s;
}

.dropdown__content.active {
  transform: scale(1);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown__footer {
  padding: 20px;
  text-align: center;
}

.notification-icon {
  width: 43px;
  height: 43px;
  border-radius: 25px 25px;
  background-color: #aaaaaa75;
  margin-left: 10px;
}

.notification-icon:hover {
  background-color: #91919181;
}

.wrap-notification-item {
  max-height: 325px;
  overflow-y: auto;
  height: 325px;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.notification-item:hover {
  background-color: rgb(233, 233, 233);
}

.notification-item-title {
  font-weight: 600;
}

.notification-item-body {
  font-weight: 500;
}

.notification-item-time {
  font-size: 13px;
  font-weight: 500;
  color: #3c7ef8;
}

.notification-btn {
  display: inline-block;
  border-radius: 15px;
}

.notification-btn:hover {
  background-color: #eeeeee;
}

.notification-btn > button {
  border: none;
  background: none;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.notification-btn.active {
  background-color: #e7f3ff;
}
.notification-btn.active:hover {
  background-color: #cfe5fa;
}
.notification-btn.active > button {
  color: #349eff;
}

.no-notification {
  text-align: center;
  margin-top: 15px;
}
/*list notifi*/

.createPost {
  width: 60%;
  max-width: 100%;
  margin: 0 auto;
  min-height: 600px;
}

.titleBar {
  margin-bottom: 16px;
}

.titleBar > p {
  font-weight: 600;
}

.pageSubTitle {
  font-size: 26px !important;
  line-height: 19px;
  font-weight: bold;
  color: #000;
}

.formNotification {
  background: #fff;
  padding: 24px;
  margin-bottom: 20px;
  border-radius: 6px;
}

.filterField {
  padding: 20px 0px;
  margin-bottom: 16px;
  border-bottom: 1px solid #eeeeee;
}

.formField {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
}
.formField:hover {
  background-color: rgb(233, 233, 233);
}
.item-list-notification {
  margin-left: 15px;
  width: 90%;
}
.item-list-notification > p {
  font-weight: 500;
  font-size: 13px;
}
.dot {
  right: 10px;
  height: 10px;
  width: 10px;
  background-color: rgb(60, 126, 248);
  border-radius: 50%;
  display: inline-block;
  padding: 0px 5px;
}

.list-notification-btn {
  display: inline-block;
  border-radius: 15px;
}

.list-notification-btn > button {
  border: none;
  background: none;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.list-notification-btn:hover {
  background-color: #d5d5d5;
}

.list-notification-btn.active {
  background-color: #e7f3ff;
}
.list-notification-btn.active:hover {
  background-color: #cfe5fa;
}
.list-notification-btn.active > button {
  color: #349eff;
}

/*style scroll bar*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}
::-webkit-scrollbar-track:hover {
  border-radius: 10px;
  background-color: #dbdbdb8e;
}

::-webkit-scrollbar-thumb {
  background: #ced0d4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c1c2c5;
}
