body {
  background-color: rgb(220, 220, 220);
}
.unsubscribe-box {
  max-width: 570px;
  margin: auto;
  text-align: center;
  padding: 2rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.container {
  position: relative;
  border-width: 0;
  border-color: transparent;
  max-width: 540px;
  margin: 0 auto;
  background-color: rgb(252, 252, 252);
  padding: 20px;
  line-height: 150%;
  border-radius: 4px;
}

.title {
  font-weight: bold;
  text-align: center;
  font-size: 25px;
  margin-bottom: 5px;
  padding: 0px;
  margin-top: 0px;
  color: rgb(35, 35, 35);
}

.inner-container {
  background: rgb(241, 241, 241);
  padding: 15px !important;
  border-radius: 10px;
}

.inner-container p {
  color: rgb(52, 52, 52);
  font-size: 16px !important;
  text-align: left;
  margin: auto;
}

.email {
  padding: 10px 0;
}

.email p {
  text-align: center;
  color: #676a6c;
}

.btn {
  display: flex;
  justify-content: space-between;
}

.btn button {
  background-color: #050505;
  color: rgb(255, 255, 255);
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 300;
  text-transform: uppercase;
  margin: 2rem auto 1rem;
  cursor: pointer;
}

.btn button:hover {
  background-color: #f8981f;
  transition: 0.3s ease;
}
