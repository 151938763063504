.tw-pagination {
  margin: 50px 0;
  padding: 0;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    li {
      display: block;
      margin: 0 3px;
      color: #999;
      font-size: 12px;
      border-radius: 50%;
      cursor: pointer;

      a {
        display: block;
        color: inherit;
        width: 30px;
        height: 30px;
        line-height: 30px;
        border-radius: 50%;
        background: transparent;
        border: none;
        user-select: none;
      }

      &:hover {
        background: #fff;
      }

      &.selected {
        background: #f8981f;
        box-shadow: "0 4px 5px 0 rgb(248 152 31 / 14%), 0 1px 10px 0 rgb(248 152 31 / 12%), 0 2px 4px -1px rgb(248 152 31 / 20%)";
        color: #fff;
      }

      &.next,
      &.previous {
        font-size: 20px;

        &.disabled {
          background: transparent;
          cursor: default;
        }
      }
    }
  }
}
