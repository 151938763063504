.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#menu-select-in-modal {
  z-index: 10000;
}

.StripeElement {
  display: block;
  margin: 10px 0;
  width: 100%;
  padding: 10px 14px;
  font-size: 1em;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  font-size: 16px;
  color: #424770;
  letter-spacing: 0.025em;
}

.StripeElement::placeholder {
  color: #aab7c4;
}

#menu-category > div,
#menu-year > div,
#menu-make > div {
  border-radius: 0;
}

#menu-category ul,
#menu-year ul,
#menu-make ul {
  padding: 0;
}

#menu-category li,
#menu-year li,
#menu-make li {
  background: transparent;
}
