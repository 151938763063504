.img-gg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.img-indeed {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.btn-gg {
  background-color: white !important;
  width: 100%;
  height: 40px;
  margin-top: 10px !important;
  color: #786e6e !important;
  text-transform: none !important;
}

.btn-indeed {
  background-color: #2557a7 !important;
  width: 100%;
  height: 40px;
  margin-top: 10px !important;
}

@media all and (max-width: 770px) {
  .big {
    display: none;
  }
}

@media all and (min-width: 770px) {
  .small {
    display: none;
  }
}