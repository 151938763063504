.box {
    width: 700px;
    max-width: 100%;
    margin: 0 auto;
  }
  .container {
    background: white;
    padding: 24px;
    margin-bottom: 30px;
  }
  .title {
    margin-bottom: 16px;
  }
  .inner-container {
    background: rgb(241, 241, 241);
    padding: 10px;
    border-radius: 10px;
  }
  .inner-container p {
    color: rgb(52, 52, 52);
    font-size: 18px;
    text-align: left;
    margin: auto;
  }
  .email {
    padding: 10px 0;
  }
  .email p {
    text-align: center;
    color: #676a6c;
  }
  .btn button {
    background-color: #050505;
    color: rgb(255, 255, 255);
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: uppercase;
    margin: 2rem auto 1rem;
    cursor: pointer;
  }
