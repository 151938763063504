.carousel.carousel-slider {
  overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
  background-color: #ffffff;
  right: -23vw;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
  background-color: #ffffff;
  left: -23vw;
}

@media only screen and (max-width: 1200px) {
  .carousel .control-next.control-arrow,
  .carousel .control-next.control-arrow:hover {
    right: -20vw !important;
  }

  .carousel .control-prev.control-arrow,
  .carousel .control-prev.control-arrow:hover {
    left: -20vw !important;
  }
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
  width: 44px;
  height: 44px;
  top: 50%;
  border-radius: 50%;
}

.carousel .control-next.control-arrow:before {
  content: "";
  border: solid #2e3a59;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 5px;
  margin-left: 1px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  content: "";
  border: solid #2e3a59;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 5px;
  margin-right: 1px;

  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carousel .slide img {
  height: 100%;
  object-fit: contain;
}
