.error-container {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  margin-top: 300px;
  text-align: center;
  font-size: 25px;
}

.error-info {
  color: black;
  margin-right: 5px;
}

.error-p {
  margin-top: 10px !important;
}

.error-back {
  margin-top: 10px !important;
  color: #0f62d2 !important;
  display: block;
}
